import React, { Component } from 'react';
import { Col, Container, Form, Row, Modal, Button, Table } from 'react-bootstrap';
import ModalDataTable from '../components/DataTable/Modal-DataTable';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ManageRequest from '../components/DataTable/ManageRequest';
import authService from '../components/api-authorization/AuthorizeService'
import ReactDOM from 'react-dom';
import { css } from "@emotion/core";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import InvoiceDetailSalvageSales from '../components/Invoice/InvoiceDetailSalvageSales';
import InvoiceDetailBerOrTurbo from '../components/Invoice/InvoiceDetailBerOrTurbo';
import InvoiceDetailOther from '../components/Invoice/InvoiceDetailOther';
import SyncLoader from "react-spinners/SyncLoader";
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from '../components/DataTable/ReactTable'
import { dateMDY } from '../DateHelper';
import OrderDetailModal from '../components/DataTable/Order-Detail-Modal';
import { orderStatus, warehouse } from '../DateHelper';
import AddInvoice from '../components/DataTable/AddInvoice';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ViewCreditRequest from '../components/DataTable/ViewCreditRequest';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AttachmentIcon from '@material-ui/icons/Attachment';
import OrderDetail from '../components/DataTable/OrderDetail';
import ViewDocument from '../components/DataTable/ViewDocument';
const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "75%", left: "50%", transform: "translate(-50%, -50%)" };

class ManageCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '', loading: true, orderdata: [], orderDetail: [], orderDetailDate: [], show: false, showdocument: false, visible: false, showInvoice: false, showInvoiceRequest: false, customerDetail: {}, thd: {
                invoiceCustomer: {}, Status: {}
            }, loading: true, total: "", credit: "", payment: "",
            OtherInvoiceDetailsById: [], SalvageinvoiceItemList: [], BerTurboInvoiceDetailsById: [], InvoiceList: [], InvoiceDetailsById: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleChange(event) {
        // this.setState({ data: event.target.value, loading: false });
        this.props.history.replace({ state: event.target.value });
    }
    componentDidMount() {
        if (this.props.location.state)
            this.manageCreditRequestData(this.props.location.state);
        else
            this.manageCreditRequestData("All");
    }
    handleClose() {
        this.setState({ show: false });
    }
    handleClosee = () => {
        this.setState({ visible: false });
    }
    handleInvoiceClose = () => {
        this.setState({ showInvoice: false });
    }
    handleviewInvoiceClose = () => {
        this.setState({ showInvoiceRequest: false });
    }
    handleviewDocumentClose = () => {
        this.setState({ showdocument: false });
    }
    handleShow() {
        this.setState({ show: true });
    }
    componentWillReceiveProps(props) {
        this.manageCreditRequestData(props.location.state);
    }

    getOrderId = (e) => {
        return e.original.orderNumber;
    }

    getOrderDate = (e) => {
        return e.original.insertDateTime
    }
    getOrderStatus = (e) => {
        return e.original.orderStatus
    }
    getInvoice = (e) => {
        return e.original.appliedToInvoice
        this.setState({
            invoiceType: e.original.appliedToInvoiceType
        });
    }

    orderCredit(rowData) {
        this.setState({
            orderNumber: rowData.original.orderNumber
        });

        // this.populateOrderDetailById(orderNumber)
        this.setState({ show: true });
    }

    invoiceCredit(Invoice, invoiceType) {
        this.setState({
            Invoice: Invoice, invoiceType: invoiceType
        });
        if (invoiceType == "ARMInvoice") {
            this.SalvageInvoiceDetailsById(Invoice);
        }
        else if (invoiceType == "BER" || invoiceType == "Turbo") {
            this.BERInvoiceDetailsById(Invoice);
        }
        else
            this.OtherInvoiceDetailsById(Invoice);

        this.setState({ visible: true });
    }
    addInvoice = (row) => {
        this.setState({
            customerId: row.original.customerId, creditRemaining: row.original.creditAmountRemaining, orderId: row.original.orderNumber
        });
        this.setState({ showInvoice: true });
    }
    viewInvoiceRequest = (row) => {
        this.setState({
            customerId: row.original.customerId, Id: row.original.id
        });
        this.setState({ showInvoiceRequest: true });
    }
    viewAttachment = (row) => {
        this.setState({
            Id: row.original.id
        });
        this.setState({ showdocument: true });
    }

    getInvoiceId = (invoiceId, invoiceType) => {
        this.setState({
            invoiceId: invoiceId, invoiceType: invoiceType, loading: true
        });
    }
    addCreditToInvoice = (orderId, invoiceid, invoiceType) => {
        this.approveCreditRequest(orderId, invoiceid, invoiceType);
    }
   
    render() {
        const columns = [
            {
                Header: 'Order #',
                accessor: 'orderNumber',
                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.orderCredit(row)}>
                            {this.getOrderId(row)}
                        </a>
                    </div>
                )
            },
            {
                Header: 'Requested Amount',
                accessor: 'creditAmountTotal',
                Cell: ({ row }) => (
                <div>
                        ${row.original.creditAmountTotal.toFixed(2)}
                    </div>
                )
            },
            {
                Header: 'Approved Amount',
                accessor: 'creditAmountApprove',
                Cell: ({ row }) => (
                    <div>
                        ${row.original.creditAmountApprove.toFixed(2)}
                    </div>
                )
            },
            {
                Header: 'Remaining Amount',
                accessor: 'creditAmountRemaining',
                Cell: ({ row }) => (
                    <div>
                        ${row.original.creditAmountRemaining.toFixed(2)}
                    </div>
                )
            },
            {
                Header: 'Credit Reason',
                accessor: 'creditReason',
            },
            {
                Header: 'Comments',
                accessor: 'comments',
            },
            {
                Header: 'Credit Request Status',
                accessor: 'status',
                Cell: ({ row }) => (
                    <div>
                        {row.original.status} &nbsp;&nbsp;
                        <div>
                            <a style={{ cursor: 'pointer', color: 'blue' }}
                                onClick={() => this.invoiceCredit(this.getInvoice(row), row.original.appliedToInvoiceType)}>
                                {this.getInvoice(row)}
                            </a>
                        </div>
                    </div>
                )

            },
            {
                Header: 'Submitted Date',
                accessor: 'insertDateTime',

                //Cell: ({ row }) => {
                //    var date = new Date(this.getOrderDate(row));
                //    var month = date.getMonth() + 1;
                //    return (month.toString().length > 1 ? month : "0" + month) + "/" + date.getDate() + "/" + date.getFullYear();
                //}
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.insertDateTime ? dateMDY(row.original.insertDateTime.substring(0, 10)) : ""}
                    </React.Fragment>
                )
            },
            {
                Header: 'Attachments',
                accessor: 'id',
                Cell: ({ row }) => {
                    if (row.original.attachmentCount > 0) {
                        return (<Tooltip title="Click to view">
                            <IconButton onClick={() => this.viewAttachment(row)} ><AttachmentIcon /></IconButton>
                        </Tooltip>
                        )
                    }
                    else {
                        return (
                            <Tooltip title="Click to view">
                                <IconButton disabled="disabled"><AttachmentIcon /></IconButton>
                            </Tooltip>
                        )
                    }
                }
            },
            {
                Header: 'Select Invoice',
                accessor: 'isApproved',
                Cell: ({ row }) => {
                    // return <div><button onClick={() => this.addInvoice(row)}>Add</button></div>
                    if (row.original.isApproved && row.original.creditAmountRemaining > 0) {
                        return (
                            <Tooltip title="Select Invoice for Credit Request">
                                <IconButton onClick={() => this.addInvoice(row)}><AddIcon /></IconButton>
                            </Tooltip>
                        )
                    }
                    else {
                        return (
                            //<div><button disabled="disabled">Add</button></div>
                            <Tooltip title="Select Invoice for Credit Request">
                                <IconButton disabled="disabled"><AddIcon /></IconButton>
                            </Tooltip>
                        )
                    }
                }
            },
            {
                Header: 'Credit Applied Invoice',
                accessor: '',
                Cell: ({ row }) => {
                    if (row.original.status == "Pending Approval" || row.original.isDenied) {
                        return (<Tooltip title="View Credit Request">
                            <IconButton disabled="disabled"><AssignmentIcon /></IconButton>
                        </Tooltip>)
                    }
                    else {
                        return (
                            < Tooltip title="View Credit Request" >
                                <IconButton onClick={() => this.viewInvoiceRequest(row)}>
                                    <AssignmentIcon />
                                </IconButton>
                            </Tooltip >
                        )

                    }
                }
            },

        ]
        let { orderdata = [] } = this.state;

        if (orderdata === null)
            return (
                <ContentLayout>
                    <Breadcrumb
                        breadcrumb={[
                            { name: "Home", path: "/" },
                            { name: "Manage Credit Requests", path: "/Managecreditrequests" }
                        ]}
                    />
                    <div className="bg-grey pt-5 pb-5">
                        <Container fluid className="pl-5 pr-5">
                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <div className="animated fadeIn">
                                            <h2 className="title">ManageCreditRequest</h2>
                                        </div>
                                    </Row>
                                    <form>
                                        <Form.Group className="mb-0 w-100 bg-white p-3 search-byshor">
                                            <Form.Label>
                                                Sort by Status:
                                            </Form.Label>
                                            <Form.Control as="select" value={this.props.location.state} onChange={this.handleChange}>
                                                <option value="All">All</option>
                                                <option value="Approved and Not Applied">Approved and Not Applied</option>
                                                <option value="Pending Approval">Pending Approval</option>
                                                <option value="Approved and Applied">Approved and Applied</option>
                                                <option value="Partially Applied">Partially Applied</option>
                                                <option value="Denied">Denied</option>
                                                <option value="Auto denied">Auto Denied</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </form>
                                </Col>
                            </Row>
                            <div className="bg-grey pt-2 pb-0">
                                <Row>
                                    <Col lg="8">
                                        <Link to="/MyCredits" className="Mcrequests_btn p-2">
                                            Add credit requests
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="content invoices-contant border-top bg-grey pt-0 pb-5">

                        <Container fluid className="pl-5 pr-5">
                            <Row className="justify-content-center">
                                <Col md="12">
                                    <div>
                                        <div>
                                            <CssBaseline />
                                            <SyncLoader
                                                css={override}
                                                size={15}
                                                color={"#17a2b8"}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                        <div>
                                            <h2>No Record Found</h2>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </ContentLayout>
            );
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "Manage Credit Requests", path: "/Managecreditrequests" }
                    ]}
                />
                <div className="bg-grey pt-5 pb-5">
                    <Container fluid className="pl-5 pr-5">
                        <Row>
                            <Col lg="8">
                                <Row>
                                    <div className="animated fadeIn">
                                        <h2 className="title">ManageCreditRequest</h2>
                                    </div>
                                </Row>
                                <form>
                                    <Form.Group className="mb-0 w-100 bg-white p-3 search-byshor">
                                        <Form.Label>
                                            Sort by Status:
                                        </Form.Label>
                                        <Form.Control as="select" value={this.props.location.state} onChange={this.handleChange}>
                                            <option value="All">All</option>
                                            <option value="Approved and Not Applied">Approved and Not Applied</option>
                                            <option value="Pending Approval">Pending Approval</option>
                                            <option value="Approved and Applied">Approved and Applied</option>
                                            <option value="Partially Applied">Partially Applied</option>
                                            <option value="Denied">Denied</option>
                                            <option value="Auto denied">Auto Denied</option>
                                        </Form.Control>
                                    </Form.Group>
                                </form>
                            </Col>
                        </Row>
                        <div className="bg-grey pt-2 pb-0">
                            <Row>
                                <Col lg="8">
                                    <Link to="/MyCredits" className="Mcrequests_btn p-2">
                                        Add credit requests
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <p><strong style={{ color: 'green' }}>{this.state.resultType === "success" ? this.state.resultMsg : ""}</strong></p>
                <p><strong style={{ color: 'red' }}>{this.state.resultType === "fail" ? this.state.resultMsg : ""}</strong></p>
                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">

                    <Container fluid className="pl-5 pr-5">
                        <Row className="justify-content-center">
                            <Col md="12">
                                <div>
                                    <div>
                                        <CssBaseline />
                                        <div style={style}>
                                            <SyncLoader
                                                css={override}
                                                size={15}
                                                color={"#fd7e14"}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                        <ReactTable
                                            columns={columns}
                                            data={this.state.orderdata}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
                <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Order Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <OrderDetail orderNumber={this.state.orderNumber} />
                        {
                            //this.state.orderDetail != null ? this.state.orderDetail.map(manifestList => {
                            //return (
                            // <div>
                            //    <OrderDetailModal orderNumber={this.state.orderNumber} sbNumber={manifestList.sbNumber} blNumber={manifestList.blNumber} warehouse={manifestList.warehouse ? warehouse(manifestList.warehouse) : ""}
                            //        hdDotCom={manifestList.hdDotCom} itemQuantity={manifestList.itemQuantity} wholesale={manifestList.cost} ContQuantity={manifestList.containerQuantity}
                            //        status={this.state.orderDetailDate.orderStatus ? orderStatus(this.state.orderDetailDate.orderStatus) : ""} DateTime={this.state.orderDetailDate.orderDateTime} salvageCategory={manifestList.salvageCategory} warehouseId={manifestList.warehouse} manifestId={manifestList.id} />
                            //</div>
                            //)
                            //}) : null
                        }
                    </Modal.Body>
                </Modal>
                <Modal centered size="xl" show={this.state.visible} onHide={this.handleClosee} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                        {this.state.invoiceType == 'ARMInvoice' ?
                            <InvoiceDetailSalvageSales invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />

                            : this.state.invoiceType == "BER" || this.state.invoiceType == "Turbo" ?
                                <InvoiceDetailBerOrTurbo invoiceId={this.state.Invoice} invoiceType={this.state.invoiceType} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.BerTurboInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                : this.state.invoiceType == 'Other' ?
                                    < InvoiceDetailOther invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.OtherInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                    : null}
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                </Modal>
                <Modal centered scrollable size="xl" show={this.state.showInvoice} onHide={this.handleInvoiceClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Select an Invoice for Credit Apply</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-12 pt-1">
                                Credit Remaining Amount
                            </div>
                            <div className="col-md-3 col-sm col-12 mb-3">
                                <input type="text" className="form-control" disabled placeholder={this.state.creditRemaining} />
                            </div>
                        </div>
                        <AddInvoice cId={this.state.customerId} CreditRemainingAmount={this.state.creditRemaining} goto={(invoiceId, invoiceType) => this.getInvoiceId(invoiceId, invoiceType)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" className="Edit_btn" onClick={() => this.addCreditToInvoice(this.state.orderId, this.state.invoiceId, this.state.invoiceType)}>Save</Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered scrollable size="xl" show={this.state.showInvoiceRequest} onHide={this.handleviewInvoiceClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>View Credit Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>  <ViewCreditRequest cId={this.state.customerId} Id={this.state.Id} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="Edit_btn" data-dismiss="modal" onClick={this.handleviewInvoiceClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered scrollable size="xl" show={this.state.showdocument} onHide={this.handleviewDocumentClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>View Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>  <ViewDocument Id={this.state.Id} />
                    </Modal.Body>
                </Modal>
            </ContentLayout>
        );
    }
    async manageCreditRequestData(status) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/ManageCreditRequests?status=' + status, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ orderdata: data.creditRequestApprovalList, loading: false });

    }
    async approveCreditRequest(orderId, invoiceid, invoiceType) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/approveCreditRequest?orderId=' + orderId + '&invoiceid=' + invoiceid + '&invoiceType=' + invoiceType, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ showInvoice: false, resultType: data.resultType, resultMsg: data.resultMessage });
        this.manageCreditRequestData("All");
    }

    async populateOrderDetailById(orderNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('Order/Detail?id=' + orderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ orderDetail: data.manifestList, loading: false });
        this.setState({ orderDetailDate: data.orderDetail, loading: false });
    }
    async BERInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/BEROrTurboManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.berOrTurboManualInvoiceItemList != null && data.berOrTurboManualInvoiceItemList != "") {
            for (var j = 0; j < data.berOrTurboManualInvoiceItemList.length; j++) {
                if (!data.berOrTurboManualInvoiceItemList[j].isCredit && !data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    subtotal += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ BerTurboInvoiceDetailsById: data.berOrTurboManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
    async SalvageInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/Invoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemList[j].paymentAmount)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async OtherInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/OtherManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.otherManualInvoiceItemList != null && data.otherManualInvoiceItemList != "") {
            for (var j = 0; j < data.otherManualInvoiceItemList.length; j++) {
                if (!data.otherManualInvoiceItemList[j].isCredit && !data.otherManualInvoiceItemList[j].isPayment) {
                    subtotal += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ OtherInvoiceDetailsById: data.otherManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
}
export default ManageCredit;


